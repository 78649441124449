export const EMPTY_MESSAGE = 'There are no available columns for this table';
export const EDITABLE_SECTION_TITLE = 'Description';
export const COLUMN_STATS_TITLE = 'Column Statistics';
export const COLUMN_LINEAGE_LIST_SIZE = 5;
export const COLUMN_LINEAGE_DOWNSTREAM_TITLE = `Top ${COLUMN_LINEAGE_LIST_SIZE} Downstream Columns`;
export const COLUMN_LINEAGE_UPSTREAM_TITLE = `Top ${COLUMN_LINEAGE_LIST_SIZE} Upstream Columns`;
export const COLUMN_LINEAGE_MORE_TEXT = 'See More';
export const HAS_COLUMN_STATS_TEXT = 'Column stats available';
export const DELAY_SHOW_POPOVER_MS = 500;
export const BLOCKQUOTE_MARKDOWN_TYPE = 'blockquote';
export const LIST_MARKDOWN_TYPE = 'list';
